<template>
  <v-dialog
    v-model="openDialog"
    width="600"
    @click:outside="closeDialog"
  >

    <v-card>
      <v-card-title class="headline info white--text">
        Detalles del cupón
      </v-card-title>
      <v-card-text class="pt-4">
        <v-row>
          <v-col sm="12" md="4" cols="12">
            <v-text-field
              v-model="coupon.code"
              label="Código"
              prepend-icon=""
              required
              readonly
              dense
            ></v-text-field>
          </v-col>
          <v-col sm="12" md="4" cols="12">
            <v-text-field
              v-model="coupon.name"
              label="Nombre"
              prepend-icon=""
              required
              readonly
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="12" md="4" cols="12">
            <v-text-field
              v-model="coupon.localPercent"
              label="Porcentaje Locales"
              prepend-icon=""
              required
              readonly
              dense
            ></v-text-field>
          </v-col>
          <v-col sm="12" md="4" cols="12">
            <v-text-field
              v-model="coupon.nationalPercent"
              label="Porcentaje Nacionales"
              prepend-icon=""
              required
              readonly
              dense
            ></v-text-field>
          </v-col>
          <v-col sm="12" md="4" cols="12">
            <v-text-field
              v-model="coupon.foreignPercent"
              label="Porcentaje Extranjeros"
              prepend-icon=""
              required
              readonly
              dense
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="closeDialog"
        >
          Cerrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  export default {
    name:"ReservationCoupon",
    props:['reservation', 'open'],
    data() {
      return {
        openDialog:this.open,
        coupon:this.reservation.coupon,
      }
    },
    methods:{
      closeDialog() {
        this.openDialog = false;
        this.$emit('close-coupon', false)
      }
    },
    mounted() {
      console.log('reservation', this.reservation.coupon);
    },
    watch:{
      open(newValue) {
        this.openDialog = newValue;
      }
    }
  }
</script>