<template>
  <div>
    <v-data-table
      v-if="reservation.payments"
      :headers="tbHeaders"
      :items="reservation.payments"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      item-key="id"
      show-expand
      class="elevation-1"
    >
      <template v-slot:top v-if="btnAdd">
        <v-toolbar flat>
          <!-- <v-toolbar-title>Acciones</v-toolbar-title> -->
          <v-spacer></v-spacer>
          <v-btn @click="openDialogAddPayment" small color="primary"><v-icon>{{mdiPlus}}</v-icon> Agregar pago</v-btn >
        </v-toolbar>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="pt-4 pb-4">
          <Stripe v-if="item.gatewayPayment == 'stripe'" :payment="item" />
          <Paypal v-if="item.gatewayPayment == 'paypal'" :payment="item" />
          <PaypalRq v-if="item.gatewayPayment == 'paypal-rq'" :payment="item" />
          <EvopayRq v-if="item.gatewayPayment == 'evopay-rq'" :payment="item" />
          <ClipTerminal v-if="item.gatewayPayment == 'clip-terminal'" :payment="item" />
          <ClipRq v-if="item.gatewayPayment == 'clip-rq'" :payment="item" />
          <TpvBank v-if="item.gatewayPayment == 'tpv-bank'" :payment="item" />
          <BankCheque v-if="item.gatewayPayment == 'bank-cheque'" :payment="item" />
          <Cash v-if="item.gatewayPayment == 'cash'" :payment="item" />
          <Courtesy v-if="item.gatewayPayment == 'courtesy'" :payment="item" />
          <TransferDeposit v-if="item.gatewayPayment == 'transfer-deposit'" :payment="item" />
        </td>
      </template>
      <template v-slot:item.gateway="{ item }">
        <span>
          <v-icon 
            :class="getIconStylePaymentStatus(item).style"
          >
            {{ getIconStylePaymentStatus(item).icon }}
          </v-icon>
        </span>
        {{ item.getGatewayName() }}
      </template>
      <!-- <template v-slot:item.fee="{ item }">
        ${{ item.getFee() }}<small>{{ item.getCurrency() }}</small>
      </template> -->
      <template v-slot:item.subtotal="{ item }">
        ${{ item.getPaidAmount() }}<small>{{ item.getCurrency() }}</small>
      </template>
      <!-- <template v-slot:item.total="{ item }">
        ${{ item.getTotalAmount() }}<small>{{ item.getCurrency() }}</small>
      </template> -->
      <template v-slot:item.actions="{ item }">
        <a v-if="item.gatewayPayment !== 'paypal' && item.gatewayPayment !== 'stripe'" href="#" style="text-decoration: none" @click="deletePayment($event, item)">
          <v-icon 
            class="mr-2"
            color="red"
          >
            <!-- @click="$router.push({path:`/backend/taquilla/reservations/${item.id}/edit`})" -->
            mdi-delete
          </v-icon>
        </a>
        <a v-if="item.gatewayPayment !== 'paypal'  && item.gatewayPayment !== 'stripe'" href="#" style="text-decoration: none" @click="openDialogEditPayment(item)">
          <v-icon 
            class="mr-2"
          >
            <!-- @click="$router.push({path:`/backend/taquilla/reservations/${item.id}/edit`})" -->
            mdi-pencil
          </v-icon>
        </a>
      </template>
    </v-data-table>
    <AddPayment @close-dialog-add-payment="changeDialogAddPayment($event)" :reservation="reservation" :open="isOpenDialogAddPayment"/>
    <EditPayment v-if="selectedPayment" @close-dialog-edit-payment="changeDialogEditPayment($event)" :reservation="reservation" :payment="selectedPayment" :open="isOpenDialogEditPayment"/>
    <ConfirmDelete
      :open.sync="confirmaDeletePayment"
      :payment="selectedPayment"
      :reservation="reservation"
      @on-cancel="cancelDeletePayment($event)"
      @on-success="successDeltePayment($event)"
      @on-error="errorDeltePayment($event)"
    />
  </div>
</template>
<script>
  import { mdiPlus } from '@mdi/js';
  import Reservation from "@/models/Reservation";
  import {NumberFormat} from "@/helpers/NumberFormat";
  import AddPayment from "@/components/reservations/payments/Add";
  import EditPayment from "@/components/reservations/payments/Edit";
  import Stripe from "@/components/reservations/payments/StripeDetails";
  import Paypal from "@/components/reservations/payments/PaypalDetails";
  import PaypalRq from "@/components/reservations/payments/PaypalRqDetails";
  import EvopayRq from "@/components/reservations/payments/EvopayRqDetails";
  import ClipTerminal from "@/components/reservations/payments/ClipTerminalDetails";
  import ClipRq from "@/components/reservations/payments/ClipRqDetails";
  import TpvBank from "@/components/reservations/payments/TpvBankDetails";
  import BankCheque from "@/components/reservations/payments/BankChequeDetails";
  import Cash from "@/components/reservations/payments/CashDetails";
  import Courtesy from "@/components/reservations/payments/CourtesyDetails";
  import TransferDeposit from "@/components/reservations/payments/TransferDepositDetails";
  import ConfirmDelete from "@/components/reservations/payments/ConfirmDelete";
  export default {
    name:"ReservationPayments",
    props:{
      reservation: {type:Object},
      btnAdd: {
        type: Boolean,
        default: true
      }
    },
    components:{ AddPayment, EditPayment, Paypal, PaypalRq, EvopayRq, ClipTerminal, ClipRq, TpvBank, BankCheque, Cash, Courtesy, TransferDeposit, ConfirmDelete, Stripe },
    data() {
      return {
        mdiPlus:mdiPlus,
        payments:null,
        observerPayments:null,
        reservationModel:new Reservation,
        expanded: [],
        isOpenDialogAddPayment: false,
        isOpenDialogEditPayment: false,
        singleExpand: false,
        tbHeaders:[
          {
            text: 'A través',
            align: 'start',
            sortable: false,
            value: 'gateway',
          },
          // {
          //   text: 'Comisión',
          //   align: 'start',
          //   sortable: false,
          //   value: 'fee',
          // },
          {
            text: 'Total',
            align: 'start',
            sortable: false,
            value: 'subtotal',
          },
          // {
          //   text: 'Total',
          //   align: 'start',
          //   sortable: false,
          //   value: 'total',
          // },
          { text: '', value: 'actions' },
          { text: '', value: 'data-table-expand' },
        ],
        selectedPayment:null,
        confirmaDeletePayment:false,
      }
    },
    mounted() {
      //this.payments = this.reservation.payments
      console.log('this.payments')
      /*this.observerPayments = this.reservationModel.getPayments(this.reservation.id).onSnapshot(snap => {
        this.payments = [];
        snap.forEach(doc => {
          this.payments.push(doc.data());
        })
      })*/
    },
    methods:{
      formatNumber(number) {
        return NumberFormat.format(number, 2, ',', '.')
      },
      openDialogAddPayment() {
        this.isOpenDialogAddPayment = true;
      },
      openDialogEditPayment(payment) {
        this.isOpenDialogEditPayment = true;
        this.selectedPayment = payment
      },
      changeDialogAddPayment(e) {
        this.isOpenDialogAddPayment = e
      },
      changeDialogEditPayment(e) {
        this.isOpenDialogEditPayment = e
        setTimeout(() => {
          this.selectedPayment = null
          }, 500)
      },
      cancelDeletePayment(data) {
        console.log('cancelDeletePayment', data)
      },
      successDeltePayment(data) {
        console.log('successDeltePayment', data)
      },
      errorDeltePayment(data) {
        console.log('errorDeltePayment', data)
      },
      deletePayment(e, item) {
        e.preventDefault()
        this.selectedPayment = item
        this.confirmaDeletePayment = true
      },
      getIconStylePaymentStatus(payment) {
        if (payment.gatewayPayment == 'cash' || payment.gatewayPayment == 'paypal') {
          return {
            style: 'success--text',
            icon: 'mdi-check-circle'
          }
        }
        if (payment.gatewayPayment == 'transfer-deposit') {
          if (payment.transferType && payment.transactionId && payment.image) {
            return {
              style: 'success--text',
              icon: 'mdi-check-circle'
            }
          }
          return {
            style: 'warning--text',
            icon: 'mdi-help-box'
          }
        } else {
          if (payment.transactionId && payment.image) {
            return {
              style: 'success--text',
              icon: 'mdi-check-circle'
            }
          }
          return {
            style: 'warning--text',
            icon: 'mdi-help-box'
          }
        }
      }
    },
  }
</script>