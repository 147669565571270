<template>
  <div>
    <v-simple-table dense >
      <template v-slot:default>
        <tbody>
          <tr>
            <td>TXN ID</td>
            <td>{{ payment.txn_id }}</td>
          </tr>
          <tr>
            <td>Negocio</td>
            <td>{{ payment.business }}</td>
          </tr>
          <tr>
            <td>Receiver Email</td>
            <td>{{ payment.receiver_email }}</td>
          </tr>
          <tr>
            <td>Fecha</td>
            <td>{{ payment.payment_date }}</td>
          </tr>
          <tr>
            <td>Cantidad pagada</td>
            <td>{{ payment.payment_gross }} {{ payment.mc_currency }}</td>
          </tr>
          <tr>
            <td>Comisión</td>
            <td>{{ payment.mc_fee }} {{ payment.mc_currency }}</td>
          </tr>
          <tr>
            <td>Payer email</td>
            <td>{{ payment.payer_email }}</td>
          </tr>
          <tr>
            <td>Payer business name </td>
            <td>{{ payment.payer_business_name }}</td>
          </tr>
          <tr>
            <td>Nombre completo</td>
            <td>{{ payment.first_name }} {{ payment.last_name }}</td>
          </tr>
          <tr>
            <td>Payer status</td>
            <td>{{ payment.payer_status }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>
<script>
  export default {
    name:'PaypalDetails',
    props:['payment']
  }
</script>