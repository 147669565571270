import BaseModel from './BaseModel';
//import Vue from 'vue';
//import lodash from 'lodash';
//import {DataConverter as CategoryExtraDataConverter} from '@/FirestoreDataConverters/CategoryExtra.js';
import {DataConverter, Currency as CurrencyData} from '@/FirestoreDataConverters/Currency.js';


export default class Currency extends BaseModel{
  constructor(options) {
    super(options);
  }
  findById(docId) {
    return this.db.collection('currencies').doc(docId).withConverter(DataConverter);
  }
  getAll() {
    return this.db.collection('currencies').withConverter(DataConverter);
    //return this.db.collection('categoriesExtras').withConverter(CategoryExtraDataConverter);
  }
  add(data, language) {
    let ref = this.db.collection('currencies').doc(data.code.toLowerCase()).withConverter(DataConverter);
    return ref.set(new CurrencyData(data)).then( () => {
      return ref.collection('currencyLanguages').doc(language.id).set({
        name:data.name,
        language:language.name
      })
    })
  }
  update(docId, data) {
    return this.db.collection('currencies').doc(docId).update(data);
  }
}