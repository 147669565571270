<template>
  <div>
    <v-simple-table dense >
      <template v-slot:default>
        <tbody>
          <tr>
            <td>Payment intent ID</td>
            <td>{{ payment.data.object.id }}</td>
          </tr>
          <tr>
            <td>Descripción</td>
            <td>{{ payment.data.object.description }}</td>
          </tr>
          <tr>
            <td>Fecha</td>
            <td>{{ $moment.unix(payment.created).format('HH:mm DD-MM-YYYY') }}</td>
          </tr>
          <tr>
            <td>Cantidad pagada</td>
            <td>${{ payment.data.object.amount / 100 }} {{ payment.data.object.currency }}</td>
          </tr>
          <tr>
            <td colspan="2"><strong>Cliente</strong></td>
          </tr>
          <tr>
            <td>Nombre </td>
            <td>{{ payment.data.object.charges.data[0].billing_details.name }}</td>
          </tr>
          <tr>
            <td>C.P.</td>
            <td>{{ payment.data.object.charges.data[0].billing_details.address.postal_code }}</td>
          </tr>
          <tr>
            <td colspan="2"><strong>Tarjeta</strong></td>
          </tr>
          <tr>
            <td>{{ payment.data.object.charges.data[0].payment_method_details.card.brand }}</td>
            <td>XXXX XXXX XXXX {{ payment.data.object.charges.data[0].payment_method_details.card.last4 }}</td>
          </tr>
          <tr>
            <td>Comprobación de CVC</td>
            <td>
              <span v-if="checkCvcIsOk(payment.data.object.charges.data[0].payment_method_details.card)">
                <v-icon class="success--text">mdi-check-circle</v-icon> Superada
              </span>
              <span v-else>
                <v-icon class="success--red">mdi-close-circle-outline</v-icon> No superada
              </span>
            </td>
          </tr>
          <tr>
            <td>Comprobación de código postal</td>
            <td>
              <span v-if="checkPostalCodeIsOk(payment.data.object.charges.data[0].payment_method_details.card)">
                <v-icon class="success--text">mdi-check-circle</v-icon> Superada
              </span>
              <span v-else>
                <v-icon class="success--red">mdi-close-circle-outline</v-icon> No superada
              </span>
            </td>
          </tr>
          <tr>
            <td colspan="2"><strong>Reservación</strong></td>
          </tr>
          <tr>
            <td>tourId</td>
            <td>{{ payment.data.object.metadata.tourId }}</td>
          </tr>
          <tr>
            <td>code</td>
            <td>{{ payment.data.object.metadata.code }}</td>
          </tr>
          <tr>
            <td>reservationId</td>
            <td>{{ payment.data.object.metadata.reservationId }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>
<script>
  export default {
    name:'StripeDetails',
    props:['payment'],
    methods: {
      checkCvcIsOk(card) {
        return card.checks.cvc_check == 'pass' 
      },
      checkPostalCodeIsOk(card) {
        return card.checks.address_postal_code_check == 'pass' 
      },
    }
  }
</script>