<template>
  <v-dialog
    v-model="openDialog"
    width="600"
    @click:outside="closeDialog"
  >

    <v-card>
      <v-card-title class="headline info white--text">
        Detalles del cupón para nacionales
      </v-card-title>
      <v-card-text class="pt-4">
        <v-row>
          <v-col sm="12" md="4" cols="12">
            <v-text-field
              v-model="coupon.code"
              label="Código"
              prepend-icon=""
              required
              readonly
              dense
            ></v-text-field>
          </v-col>
          <v-col sm="12" md="4" cols="12">
            <v-text-field
              v-model="coupon.name"
              label="Nombre"
              prepend-icon=""
              required
              readonly
              dense
            ></v-text-field>
          </v-col>
          <v-col sm="12" md="4" cols="12">
            <v-text-field
              v-model="coupon.nationalPercent"
              label="Porcentaje"
              prepend-icon=""
              required
              readonly
              dense
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="closeDialog"
        >
          Cerrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  export default {
    name:"ReservationNationalCoupon",
    props:['reservation', 'open'],
    data() {
      return {
        openDialog:this.open,
        coupon:this.reservation.couponNational,
      }
    },
    methods:{
      closeDialog() {
        this.openDialog = false;
        this.$emit('close-national-coupon', false)
      }
    },
    mounted() {
      //console.log('reservation', this.reservation);
    },
    watch:{
      open(newValue) {
        this.openDialog = newValue;
      }
    }
  }
</script>