<template>
  <div>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              Pax
            </th>
            <th class="text-left">
              Cantidad
            </th>
            <th class="text-left">
              P.Individual
            </th>
            <th class="text-left">
              Subtotal
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Adultos</td>
            <td>{{ extra.quantityAdults }}</td>
            <td>{{ extra.priceAdult }}</td>
            <td>{{ extra.quantityAdults * extra.priceAdult }}</td>
          </tr>
          <tr>
            <td>Niños</td>
            <td>{{ extra.quantityChildren }}</td>
            <td>{{ extra.priceChild }}</td>
            <td>{{ extra.quantityChildren * extra.priceChild }}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th colspan="3">Total</th>
            <th>{{ getTotal() }}</th>
          </tr>
        </tfoot>
      </template>
    </v-simple-table>
  </div>
</template>
<script>
  export default {
    name: 'ExtraRegular',
    props: ['extra'],
    methods: {
      getTotal() {
        return (this.extra.quantityAdults * this.extra.priceAdult) +
          (this.extra.quantityChildren * this.extra.priceChild)
      }
    }
  }
</script>