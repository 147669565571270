<template>
  <div>
    <v-data-table
      v-if="reservation.extras"
      :headers="tbHeaders"
      :items="reservation.extras"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      item-key="id"
      show-expand
      class="elevation-1"
    >
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="pt-4 pb-4">
          <Atv v-if="item.type === 'atv'" :extra="item"/>
          <Regullar v-if="item.type === 'regular'" :extra="item"/>
        </td>
      </template>
    </v-data-table>
  </div>
</template>
<script>
  import Regullar from "@/components/reservations/extras/Regular";
  import Atv from "@/components/reservations/extras/Atv";
  export default {
    name: 'ReservationExtras',
    props: ['reservation'],
    components: {Regullar, Atv},
    data() {
      let headers = [
        {
          text: 'Nombre',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        {
          text: 'Adultos',
          align: 'start',
          sortable: false,
          value: 'quantityAdults',
        },
        {
          text: 'Niños',
          align: 'start',
          sortable: false,
          value: 'quantityChildren',
        },
        {
          text: 'P.Adulto',
          align: 'start',
          sortable: false,
          value: 'priceAdult',
        },
        {
          text: 'P.Menor',
          align: 'start',
          sortable: false,
          value: 'priceChild',
        },
        // { text: '', value: 'actions' },
        { text: '', value: 'data-table-expand' },
      ]
      if (this.reservation.panel == 'divers') {
        headers = [
        {
          text: 'Nombre',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        {
          text: '#',
          align: 'start',
          sortable: false,
          value: 'quantityAdults',
        },
        {
          text: 'P.Individual',
          align: 'start',
          sortable: false,
          value: 'priceAdult',
        },
        // { text: '', value: 'actions' },
        { text: '', value: 'data-table-expand' },
      ]
      }
      return {
        singleExpand: false,
        expanded: [],
        tbHeaders: headers,
      }
    }
  }
</script>