<template>
  <v-form
    ref="form"
    v-model="validForm"
    lazy-validation
  >
  <Loading :active="loading" :fullPage="true" />
  <v-row>
    <v-col sm="12" md="6">
      <v-text-field
        v-model="customer.name"
        label="Nombre"
        :rules="[rules.required]"
        required
        dense
      ></v-text-field>
    </v-col>
    <v-col sm="12" md="6">
      <v-text-field
        v-model="customer.lastname"
        label="Apellidos"
        :rules="[rules.required]"
        required
        dense
      ></v-text-field>
    </v-col>
  </v-row>
  <v-row class="mt-1">
    <v-col sm="12" md="6">
      <v-text-field
        v-model="customer.email"
        label="Email"
        :rules="[rules.required]"
        required
        dense
      ></v-text-field>
    </v-col>
    <v-col sm="12" md="6" class="">
      <MazPhoneNumberInput
        style="margin-top: -18px"
        v-model="customer.nationalNumber"
        @update="updateCountry"
        :default-country-code="customer.countryCode"
        :default-phoner-number="customer.formatNational"
      />
    </v-col>
  </v-row>
  <div v-if="reservation.panel === 'divers'" class="mb-3">
    <h4>Guías</h4>
    <ol>
      <li v-for="(g, index) in reservation.nameGuides" :key="index">{{g}}</li>
    </ol>
    <v-divider/>
  </div>
  <!-- <v-divider></v-divider> -->
  <v-btn
    small
    color="primary"
    @click="update"
    dark
  >
    <v-icon>{{mdiContentSave}}</v-icon>
    Guardar
  </v-btn>
      <!-- https://github.com/hantrongbinh/vue-country-code -->
</v-form>
</template>
<script>
  import { MazPhoneNumberInput } from 'maz-ui'
  import { mdiContentSave } from "@mdi/js"
  import Reservation from "@/models/Reservation"
  // import Loading from "@/components/Loading";
  export default {
    name:"Customer",
    props:['reservation'],
    components: { MazPhoneNumberInput },
    data() {
      return {
        mdiContentSave:mdiContentSave,
        loading:false,
        validForm:true,
        phoneNumber:'',
        country:this.reservation.customer,
        rules:{
          required: v => !!v || 'El campo es obligatorio',
        }
      }
    },
    methods:{
      onSelect({name, iso2, dialCode}) {
        console.log(name, iso2, dialCode);
      },
      async update() {
        this.loading = true
        const reservation = new Reservation
        let customer = {...this.customer, ... this.country}
        await reservation.updateCustomer(this.reservation.id, customer, this.reservation.customer)
        this.loading = false
      },
      updateCountry(e) {
        delete e.type
        delete e.uri
        delete e.isValid
        delete e.e164
        this.country = e
      }
    },
    computed:{
      customer() {
        let customer = {...this.reservation.customer}

        customer.nationalNumber = customer.nationalNumber || customer.formatNational
        return customer
      }
    }
  }
</script>
<style>
  .maz-border-radius{
    border-radius: 0;
    border-color: rgba(0, 0, 0, 0.42);
    border-width: 0 0 thin 0;
  }
  .maz-phone-number-input .country-selector .maz-select__options-list .maz-input{
    border-radius: 0px;
  }
  .maz-phone-number-input .country-selector{
    max-width: 7rem;
    flex: 0 0 7rem;
    min-width: 7rem;
  }
</style>