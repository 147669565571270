<template>
  <div>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
            </th>
            <th class="text-left">
              Cantidad
            </th>
            <th class="text-left">
              Pax
            </th>
            <th class="text-left">
              Precio x persona
            </th>
            <th class="text-left">
              Subtotal
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>ATVs Individual</td>
            <td>{{ extra.distribution.single }}</td>
            <td>{{ extra.distribution.single }}</td>
            <td>{{ extra.priceAdult }}</td>
            <td>{{ extra.distribution.single * extra.priceAdult }}</td>
          </tr>
          <tr>
            <td>ATVs Compartida</td>
            <td>{{ extra.distribution.shared }}</td>
            <td>{{ extra.distribution.adultsShared }}</td>
            <td>{{ extra.priceAdultShared }}</td>
            <td>{{ extra.distribution.adultsShared * extra.priceAdultShared }}</td>
          </tr>
          <tr>
            <td>Niños</td>
            <td>{{ extra.distribution.children }}</td>
            <td></td>
            <td>{{ extra.priceChild }}</td>
            <td>{{ extra.distribution.children * extra.priceChild }}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th class="text-right" colspan="4">Total</th>
            <th>{{ extra.distribution.total }}</th>
          </tr>
        </tfoot>
      </template>
    </v-simple-table>
  </div>
</template>
<script>
  export default {
    name: 'ExtraAtv',
    props: ['extra'],
    methods: {
      getTotal() {
        return (this.extra.quantityAdults * this.extra.priceAdult) +
          (this.extra.quantityChildren * this.extra.priceChild)
      }
    }
  }
</script>