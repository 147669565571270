<template>
  <div>
    <h5 class="d-flex">
      <span class="" v-if="reservation.currency != 'usd'">
        Tarifa
        <v-chip
          class=""
          color="success"
          x-small
        >
          {{reservation.typeRate == 'foreign' ? 'Regular' : 'Local'}}
        </v-chip>
      </span>
      <span class="ml-auto" v-if="reservation.currency != 'usd'">
        Tipo de cambio
        <v-chip
          class=""
          color="primary"
          x-small
        >
          {{numberFormat(exchangeRateConverted)}}
        </v-chip>
      </span>
    </h5>
    <v-row>
      <v-col sm="12" md="6" v-if="reservation.rate">
        <template v-if="reservation.panel === 'divers'">
          <v-simple-table >
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Producto/Servicio
                  </th>
                  <th class="text-left">
                    Precio Individual
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="reservation.guides > 0">
                  <td>Guía</td>
                  <td>${{ numberFormat(reservation.rate.diverPrice / reservation.exchangeRate) }}<small>{{reservation.currency}}</small></td>
                </tr>
                <tr v-if="reservation.adults > 0">
                  <td>Cliente</td>
                  <td>${{ numberFormat(reservation.rate.customerPrice / reservation.exchangeRate) }}<small>{{reservation.currency}}</small></td>
                </tr>
                <tr v-if="reservation.immersions > 0">
                  <td>Inmersión Extra</td>
                  <td>${{ numberFormat(reservation.rate.immersionPrice / reservation.exchangeRate) }}<small>{{reservation.currency}}</small></td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <div v-if="reservation.lastReservation">
            <small class="primary--text">
              Ultima reservación (Checkin) {{ reservation.lastReservation.code }}
              el día {{ $moment.unix(reservation.lastReservation.date.seconds).format('ddd DD MMM YYYY, HH:mm a') }}
              <span class="error--text">(Al momento de crear la reservación)</span>
            </small>
          </div>
          <small v-if="reservation.discount && !reservation.lastReservation" class="info--text">
            {{reservation.discount.message}}
          </small>

        </template>
        <v-simple-table v-if="reservation.panel !== 'divers'">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Pax
                </th>
                <th class="text-left">
                  Precio
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="reservation.adults > 0">
                <td>Adultos</td>
                <td>${{numberFormat(reservation.rate.adult)}}<small>{{reservation.currency}}</small></td>
              </tr>
              <tr v-if="reservation.elderlies > 0">
                <td>A.mayores</td>
                <td>${{numberFormat(reservation.rate.elderly)}}<small>{{reservation.currency}}</small></td>
              </tr>
              <tr v-if="reservation.children > 0">
                <td>Niños</td>
                <td>${{numberFormat(reservation.rate.child)}}<small>{{reservation.currency}}</small></td>
              </tr>
              <tr v-if="reservation.infants > 0">
                <td>Infantes</td>
                <td>${{numberFormat(reservation.rate.infant)}}<small>{{reservation.currency}}</small></td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <v-col sm="12" md="6">
        <v-simple-table >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Desgloce de precios
                </th>
                <th class="text-left">
                  {{reservation.currency}}
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-if="reservation.panel == 'divers'">
                <tr>
                  <td>Guías ({{reservation.guides}})</td>
                  <td>
                    ${{numberFormat(reservation.guides * reservation.rate.diverPrice  / reservation.exchangeRate)}}
                  </td>
                </tr>
                <tr>
                  <td>Clientes({{reservation.adults}})</td>
                  <td>${{numberFormat(reservation.adults * reservation.rate.customerPrice  / reservation.exchangeRate)}}</td>
                </tr>
                <tr>
                  <td>Inmersiones Extras({{reservation.immersions}})</td>
                  <td>${{numberFormat(totalImmersions)}}</td>
                </tr>
                <tr v-if="reservation.totalExtras && reservation.totalExtras > 0">
                  <td>Extras</td>
                  <td>${{numberFormat(reservation.totalExtras)}}</td>
                </tr>
                <tr>
                  <td><strong>Subtotal</strong></td>
                  <td><strong>${{numberFormat(reservation.subtotal + totalImmersions + reservation.totalExtras)}}</strong></td>
                </tr>
                <tr v-if="reservation.discount">
                  <td :class="reservation.discount ? 'error--text' : ''">Guías ({{reservation.discount.guides}})</td>
                  <td :class="reservation.discount ? 'error--text' : ''">
                    {{ reservation.discount ? '-' : ''}}${{numberFormat(reservation.discount.value)}}
                  </td>
                </tr>
                <tr>
                  <td class="green--text"><strong>Total</strong></td>
                  <td class="green--text"><strong>${{numberFormat(reservation.total)}}</strong></td>
                </tr>
                <tr>
                  <td colspan="2" style="height:10px"></td>
                </tr>
              </template>
              <template v-if="reservation.panel !== 'divers'">
                <tr>
                  <td>Subtotal</td>
                  <td>${{numberFormat(reservation.subtotal)}}</td>
                </tr>
                <tr v-if="reservation.totalExtras > 0">
                  <td>Extras</td>
                  <td>${{numberFormat(reservation.totalExtras)}}</td>
                </tr>
                <tr v-if="reservation.transportation">
                  <td>Transporte (Vans x {{ reservation.transportation.numVehicles }})</td>
                  <td>${{numberFormat(reservation.transportation.total)}}</td>
                </tr>
                <tr v-if="reservation.couponNational">
                  <td class="red--text">Cupón <a href="#" @click="openDialogNationalCoupon($event)">({{reservation.couponNational.code}})</a></td>
                  <td class="red--text">-${{numberFormat(reservation.totalCouponNational)}}</td>
                </tr>
                <tr v-if="reservation.coupon">
                  <td class="red--text">Cupón <a href="#" @click="openDialogCoupon($event)">({{reservation.coupon.code}})</a></td>
                  <td class="red--text">-${{numberFormat(reservation.totalCoupon)}}</td>
                </tr>
                <tr v-if="reservation.couponExtra">
                  <td class="red--text">Cupón ({{reservation.totalCouponExtra.code}})</td>
                  <td class="red--text">-${{numberFormat(reservation.totalCouponExtra)}}</td>
                </tr>
                <tr>
                  <td class="green--text"><strong>Total</strong></td>
                  <td class="green--text"><strong>${{numberFormat(reservation.total)}}</strong></td>
                </tr>
              </template>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <NationalCoupon v-if="reservation.couponNational" :reservation="reservation" :open="openNationalCoupon" @close-national-coupon="changeDialogCouponNational($event)"/>
    <Coupon v-if="reservation.coupon" :reservation="reservation" :open="openCoupon" @close-coupon="changeDialogCoupon($event)"/>
  </div>
</template>
<script>
  import {NumberFormat} from "@/helpers/NumberFormat";
  import NationalCoupon from "@/components/reservations/NationalCoupon";
  import Coupon from "@/components/reservations/Coupon";
  export default {
    name:"ReservationRates",
    props:['reservation'],
    components:{NationalCoupon, Coupon},
    data() {
      return {
        openNationalCoupon:false,
        openCoupon:false,
        totalImmersions: this.reservation.immersions * this.reservation.adults * this.reservation.rate.immersionPrice / this.reservation.exchangeRate
      }
    },
    computed:{
      exchangeRateConverted() {
        return 1 / this.reservation.exchangeRate;
      }
    },
    methods:{
      numberFormat(amount) {
        return NumberFormat.format(amount, 2, ',', '.')
      },
      openDialogNationalCoupon(e) {
        e.preventDefault();
        this.openNationalCoupon = true;
      },
      openDialogCoupon(e) {
        e.preventDefault();
        this.openCoupon = true;
      },
      changeDialogCoupon(e) {
        this.openCoupon = e;
      },
      changeDialogCouponNational(e) {
        this.openNationalCoupon = e;
      }
    }
  }
</script>