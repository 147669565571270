<template>
  <div class="text-center">
    <v-dialog
      v-if="payment"
      v-model="open"
      width="700"
      persistent
    >
      <v-card>
        <v-card-title class="headline info white--text">
          El pago se eliminara permanentemente
        </v-card-title>

        <v-card-text>
          <h4 v-if="payment.gatewayPayment !== 'cash'" class="pt-4 pb-4 red--text">
            El pago con ID: <strong>{{ payment.transactionId }}</strong>
          </h4>
          <h4 v-else class="pt-4 red--text">
            El pago por <strong>{{ payment.amount }}{{ payment.currency }}</strong>
          </h4>
          <v-form
            ref="form"
            v-model="validForm"
            lazy-validation
          >
            <loading :active.sync="myLoading.isLoading" :is-full-page="myLoading.fullPage"></loading>
            <v-textarea
                v-model="reason"
                name="input-7-1"
                label="Razon"
                :rules="[rules.required]"
                rows="3"
                placeholder="Ingrese una descripción de por que se elimina el pago"
              ></v-textarea>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="$emit('update:open', false)"
          >
            Cerrar
          </v-btn>
          <v-btn
            color="red"
            class="white--text"
            :disabled="!validForm"
            @click="deletePayment"
          >
            <v-icon>mdi-delete</v-icon> Eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      :timeout="-1"
    >
      {{snackbar.message}}
      <template v-slot:action="{ attrs }">
        <v-btn
          color=""
          text
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import Reservation from "@/models/Reservation"
export default {
  name: 'ConfirmDelete',
  props: ['payment', 'reservation', 'open'],
  data() {
    return {
      reason: '',
      validForm: true,
      myLoading:{
        isLoading:false,
        fullPage:false
      },
      rules:{
        required: v => !!v || 'El campo es obligatorio',
      },
      snackbar:{
        show:false,
        color:'',
        message:''
      },
    }
  },
  methods: {
    deletePayment() {
      if (this.$refs.form.validate()) {
        this.myLoading.isLoading = true
        const reservationModel = new Reservation
        reservationModel
        .deletePayment(this.reservation.id, this.payment.id, this.reason).
        then(async () => {
          await reservationModel.checkPayments(this.reservation)
          this.snackbar.show = true
          this.snackbar.color = 'green'
          this.snackbar.message = `El pago se elimino correctamente`
          this.$emit('on-success', {
            status:'ok', 
          })
          this.myLoading.isLoading = false
          this.$emit('update:open', false)
        })
        .catch((error) => {
          console.error("Error removing document: ", error);
          this.snackbar.show = true
          this.snackbar.color = 'red'
          this.snackbar.message = `Error al eliminar el pago, por favor contacte al Administrador si el problema persiste`
          this.myLoading.isLoading = false
          this.$emit('on-error', {
            error: {
              message: this.snackbar.message
            }
          })
        })
      }
    }
  }
}
</script>