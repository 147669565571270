<template>
  <v-dialog
      v-model="openDialog"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="headline info white--text">
          Actualizar status de pago
        </v-card-title>

        <v-card-text class="pt-4">
          <v-form
            ref="form"
            v-model="validForm"
            lazy-validation
          >
            <loading :active.sync="myLoading.isLoading" :is-full-page="myLoading.fullPage"></loading>
            <v-select
              v-model="paymentStatus"
              :items="items"
              label="Estatus"
              persistent-hint
            ></v-select>
            <v-textarea
              v-model="description"
              name="input-7-1"
              label="Descripcion"
              :rules="[rules.required]"
              rows="3"
              prepend-icon="mdi-message-alert-outline"
              placeholder="Ingrese una descripción"
            ></v-textarea>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="closeDialog"
          >
            Cerrar
          </v-btn>
          <v-btn
            color="primary"
            :disabled="!validForm"
            @click="validateForm"
          >
            <v-icon>mdi-content-save</v-icon> Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
<script>
import ReservationModel from '@/models/Reservation';
import Loading from 'vue-loading-overlay';
export default {
  name: 'UpdatePaymentStatus',
  props: ['reservation', 'open'],
  components:{Loading},
  data() {
    return {
      paymentStatus: this.reservation.paymentStatus,
      description: '',
      items:['pending', 'confirmed'],
      openDialog: this.open,
      rules:{
        required: v => !!v || 'El campo es obligatorio',
      },
      validForm:true,
      myLoading:{
        isLoading:false,
        fullPage:false
      }
    }
  },
  methods: {
    closeDialog() {
      this.openDialog = false
      this.$emit('close-dialog-update-payment-status', false)
    },
    async validateForm() {
      try {
        if (this.$refs.form.validate()) {
          this.myLoading.isLoading = true
          const model = new ReservationModel
          await model.updatePaymentStatus(this.reservation, {
            paymentStatus: this.paymentStatus,
            description: this.description
          })
          this.myLoading.isLoading = false
          this.openDialog = false
          this.$emit('close-dialog-update-payment-status', true)
          this.$emit('on-success-update-payment-status', true)
          console.log('ok');
        }
      } catch (e) {
        this.myLoading.isLoading = false
        this.$emit('on-error-update-payment-status', e)
      }
    },
  },
  watch:{
    open(newValue) {
      this.openDialog = newValue;
    }
  }
}
</script>