<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600"
  >
    <v-card>
      <v-card-title class="headline info white--text">
        Subir IDs de los clientes
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5">
        <Loading :active="loadingSendCoupon" :fullPage="true" />
        <div>
          <v-list
            subheader
            two-line
          >
            <!-- <v-subheader inset>Archivos</v-subheader> -->

            <v-list-item
              v-for="(i, index) in ids.adults"
              :key="index"
            >
              <v-list-item-avatar>
                <v-img v-if="i.data" :src="i.data.url"></v-img>
                <v-icon
                  v-else
                  class="grey lighten-1"
                  dark
                >
                  mdi-cloud-alert
                </v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <!-- <v-list-item-title >Adulto {{ index.slice(1) }}</v-list-item-title> -->
                <v-list-item-title >Archivo</v-list-item-title>

                <v-list-item-subtitle>
                  <a v-if="i.data" :href="i.data.url">Ver archivo</a>
                  <span v-else>Aun no se ha cargado un archivo</span>
                  <v-progress-linear v-if="ids.adults[index].uploadValue !== null" :value="ids.adults[index].uploadValue"></v-progress-linear>
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-file-input
                  v-model="ids.adults[index].image"
                  label="Seleccione"
                  style="width: 200px"
                  :rules="[rules.required]"
                  accept="image/png, image/jpeg, image/bmp"
                  prepend-icon="mdi-paperclip"
                  @change="uploadImage(index, 'adult')"
                ></v-file-input>
                <!-- <v-btn icon>
                  <v-icon color="grey lighten-1">mdi-information</v-icon>
                </v-btn> -->
              </v-list-item-action>
            </v-list-item>
            <v-list-item
              v-for="(i, index) in ids.elderlies"
              :key="index"
            >
              <v-list-item-avatar>
                <v-img v-if="i.data" :src="i.data.url"></v-img>
                <v-icon
                  v-else
                  class="grey lighten-1"
                  dark
                >
                  mdi-cloud-alert
                </v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title >Adulto Mayor {{ index.slice(1) }}</v-list-item-title>

                <v-list-item-subtitle>
                  <a v-if="i.data" :href="i.data.url">Ver archivo</a>
                  <span v-else>Aun no se ha cargado un archivo</span>
                  <v-progress-linear v-if="ids.elderlies[index].uploadValue !== null" :value="ids.elderlies[index].uploadValue"></v-progress-linear>
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-file-input
                  v-model="ids.elderlies[index].image"
                  label="Seleccione"
                  style="width: 200px"
                  :rules="[rules.required]"
                  accept="image/png, image/jpeg, image/bmp"
                  prepend-icon="mdi-paperclip"
                  @change="uploadImage(index, 'elderly')"
                ></v-file-input>
                <!-- <v-btn icon>
                  <v-icon color="grey lighten-1">mdi-information</v-icon>
                </v-btn> -->
              </v-list-item-action>
            </v-list-item>
            <v-list-item
              v-for="(i, index) in ids.children"
              :key="index"
            >
              <v-list-item-avatar>
                <v-img v-if="i.data" :src="i.data.url"></v-img>
                <v-icon
                  v-else
                  class="grey lighten-1"
                  dark
                >
                  mdi-cloud-alert
                </v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title >Niño {{ index.slice(1) }}</v-list-item-title>

                <v-list-item-subtitle>
                  <a v-if="i.data" :href="i.data.url">Ver archivo</a>
                  <span v-else>Aun no se ha cargado un archivo</span>
                  <v-progress-linear v-if="ids.children[index].uploadValue !== null" :value="ids.children[index].uploadValue"></v-progress-linear>
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-file-input
                  v-model="ids.children[index].image"
                  label="Seleccione"
                  style="width: 200px"
                  :rules="[rules.required]"
                  accept="image/png, image/jpeg, image/bmp"
                  prepend-icon="mdi-paperclip"
                  @change="uploadImage(index, 'child')"
                ></v-file-input>
                <!-- <v-btn icon>
                  <v-icon color="grey lighten-1">mdi-information</v-icon>
                </v-btn> -->
              </v-list-item-action>
            </v-list-item>
            <v-list-item
              v-for="(i, index) in ids.infants"
              :key="index"
            >
              <v-list-item-avatar>
                <v-img v-if="i.data" :src="i.data.url"></v-img>
                <v-icon
                  v-else
                  class="grey lighten-1"
                  dark
                >
                  mdi-cloud-alert
                </v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title >Infante {{ index.slice(1) }}</v-list-item-title>

                <v-list-item-subtitle>
                  <a v-if="i.data" :href="i.data.url">Ver archivo</a>
                  <span v-else>Aun no se ha cargado un archivo</span>
                  <v-progress-linear v-if="ids.infants[index].uploadValue !== null" :value="ids.infants[index].uploadValue"></v-progress-linear>
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-file-input
                  v-model="ids.infants[index].image"
                  label="Seleccione"
                  style="width: 200px"
                  :rules="[rules.required]"
                  accept="image/png, image/jpeg, image/bmp"
                  prepend-icon="mdi-paperclip"
                  @change="uploadImage(index, 'infant')"
                ></v-file-input>
                <!-- <v-btn icon>
                  <v-icon color="grey lighten-1">mdi-information</v-icon>
                </v-btn> -->
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click="closeDialog"
        >
          Cancelar
        </v-btn>
        <!-- <v-btn
          color="primary"
          @click="uploadIDs"
        >
          <v-icon> mdi-upload </v-icon>
          Enviar
        </v-btn> -->
      </v-card-actions>
    </v-card>
    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      :timeout="-1"
    >
      {{snackbar.message}}
      <template v-slot:action="{ attrs }">
        <v-btn
          color=""
          text
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-dialog>
</template>
<script>
  import lodash from 'lodash';
  import ReservationModel from '@/models/Reservation';
  export default {
    name: 'UploadIDs',
    props:{
      open: {
        type: Boolean,
        required: true,
      },
      reservation: {
        type: Object,
        required: true,
      }
    },
    data() {
      // let adults = this.reservation.adults
      // let elderlies = this.reservation.elderlies
      // let children = this.reservation.children
      // let infants = this.reservation.infants
      let ids = {
        adults: {},
        elderlies: {},
        children: {},
        infants: {},
      }
      ids.adults['a'+1] = {
        image: null,
        uploadValue: null,
        data: false,
      }
      /*for (var i = 1; i <= adults; i++) {
        ids.adults['a'+i] = {
          image: null,
          uploadValue: null,
          data: false,
        }
      }
      for (var i2 = 1; i2 <= elderlies; i2++) {
        ids.elderlies['e'+i2] = {
          image: null,
          uploadValue: null,
          data: false,
        }
      }
      for (var i3 = 1; i3 <= children; i3++) {
        ids.children['c'+i3] = {
          image: null,
          uploadValue: null,
          data: false,
        }
      }
      for (var i4 = 1; i4 <= infants; i4++) {
        ids.infants['i'+i4] = {
          image: null,
          uploadValue: null,
          data: false,
        }
      }*/
      return {
        loadingSendCoupon: false,
        dialog: this.open,
        ids: ids,
        lodash: lodash,
        reservationModel: new ReservationModel,
        rules:{
          required: v => !!v || 'El campo es obligatorio',
          number : v => {
            const pattern = /^[0-9]+([.][0-9]+)?$/
            return pattern.test(v) || 'Cantidad invalida'
          },
        },
        snackbar:{
          show:false,
          color:'',
          message:''
        },
      }
    },
    methods: {
      uploadIDs() {
        console.log('subir');
      },
      closeDialog() {
        this.dialog = false
        this.$emit('close-dialog-upload-ids', false)
      },
      uploadImage(index, type) {
        let item
        if (type === 'adult') {
          item = this.ids.adults[index]
        }
        if (type === 'elderly') {
          console.log('this.ids.elderlies[index]', this.ids.elderlies[index]);
          item = this.ids.elderlies[index]
        }
        if (type === 'child') {
          item = this.ids.children[index]
        }
        if (type === 'infant') {
          item = this.ids.infants[index]
        }
        if (item.image) {
          let upload = this.reservationModel.uploadIdentification(this.reservation.id, item.image, index);
          upload.ref.on(`state_changed`, snapshot=> {
              item.uploadValue = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
            },
            error => {
              console.log(error.message);
              this.showSnackbarError('Error al subir la imagen')
            },
            ()=>{
              item.uploadValue = 100;
              upload.ref.snapshot.ref.getDownloadURL().then((url)=>{
                const idInfo = {
                  fullpath: upload.image.fullpath,
                  name: upload.image.name,
                  url: url,
                  pax: type + index.slice(1),
                  type: type
                }
                this.reservationModel.addIdentification(this.reservation.id, index, idInfo)
                  .then(() => {
                    item.uploadValue = null
                    item.image = null;
                    this.showSnackbarSuccess('La imagen se subio correctamente')
                  })
                  .catch(error => {
                    console.log('error', error);
                    this.showSnackbarError('Error al agregar la imagen en la coleccion');
                  })
              });
            }
          );
        }
        //console.log('ids', this.ids.adults[index].image)
      },
      showSnackbarSuccess(message) {
        this.snackbar.show = true;
        this.snackbar.message = message;
        this.snackbar.color = 'success';
      },
      showSnackbarError(message) {
        this.snackbar.show = true;
        this.snackbar.message = message;
        this.snackbar.color = 'error';
      },
    },
    mounted() {
      this.reservationModel.getIdentifications(this.reservation.id).onSnapshot((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const data = doc.data()
          if (data.type == 'adult') {
            this.ids.adults[doc.id].data = data
          }
          if (data.type == 'elderly') {
            this.ids.elderlies[doc.id].data = data
          }
          if (data.type == 'child') {
            this.ids.children[doc.id].data = data
          }
          if (data.type == 'infant') {
            this.ids.infants[doc.id].data = data
          }
        });
      })
    },
    watch:{
      open(value) {
        this.dialog = value
      }
    }
  }
</script>