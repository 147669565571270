<template>
  <div>
    <v-simple-table dense >
      <template v-slot:default>
        <tbody>
          <tr>
            <td>Fecha</td>
            <td>{{ $moment.unix(payment.date.seconds).format('DD MMMM YYYY - HH:mm') }}hrs</td>
          </tr>
          <tr>
            <td>Autorizado por:</td>
            <td>
              {{ payment.authorizedBy }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>
<script>
  export default {
    name:'CourtesyDetails',
    props:['payment']
  }
</script>