<template>
  <div style="">
    <portal to="title-page">
      <h1>Reservacion : {{reservation ? reservation.code : 'Por favor espere'}}</h1>
    </portal>
    <Loading :active="loading"/>
    <v-row v-if="reservation">
      <v-col sm="12" md="8">
        <EditReservation :reservation="reservation"/>
        <!-- <v-card class="mx-auto elevation-1">
          <v-card-title dense>
            Datos de la reservacion
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <EditReservation :reservation="reservation"/>
          </v-card-text>
        </v-card> -->
      </v-col>
      <v-col sm="12" md="4">
        <History :reservation="reservation"/>
      </v-col>
    </v-row>
  </div>
</template>
<script>
  import Vue from "vue";
  import Reservation from "@/models/Reservation";
  import Loading from "@/components/Loading";
  import History from "@/components/reservations/History";
  import EditReservation from "@/components/reservations/Edit";
  Vue.use('Loading', Loading);
  //Vue.component('History', History);
  export default {
    name:'TaquillaReservation',
    components:{History, EditReservation},
    data() {
      return {
        reservationId:this.$route.params.id,
        reservationModel:new Reservation(),
        reservation:null,
        unsubscribe:null,
        loading:true,
      }
    },
    created () {
      this.$store.commit('SET_LAYOUT', 'Taquilla')
    },
    mounted() {
      this.unsubscribe = this.reservationModel.getById(this.reservationId).onSnapshot(snap => {
        this.reservation = snap.data()
        this.reservation.setPayments()
        this.loading = false;
      })
    },
    methods:{
    },
    //computed: mapState(['dialogConfirmedInfo'])
  }
</script>