<template>
  <div>
    <v-dialog
      v-model="openDialog"
      class="dialogAddPayment"
      persistent
    >
      <v-card>
        <v-card-title class="headline info white--text">
          Editar pago
        </v-card-title>

        <v-card-text class="pt-4">
          <v-form
            ref="form"
            v-model="validForm"
            lazy-validation
          >
            <loading :active.sync="myLoading.isLoading" :is-full-page="myLoading.fullPage"></loading>
            <!-- <v-row> -->
              <!-- <v-col cols="12" md="5"> -->
                <v-row>
                  <v-col cols="12" md="12">
                    <v-select
                      v-model="gateway"
                      :items="gateways"
                      item-text="name"
                      item-value="value"
                      label="Pago mediante"
                      persistent-hint
                      return-object
                      disabled
                      v-on:change="changeGateway"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-menu
                      v-model="menuDate"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="paymentDate"
                          label="Fecha de pago"
                          prepend-icon="mdi-calendar"
                          disabled
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-if="!readonlyDateTime"
                        v-model="paymentDate"
                        @input="menuDate = false"
                        :readonly="readonlyDateTime"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-menu
                      ref="menu"
                      v-model="menuTime"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="paymentTime"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="paymentTime"
                          label="Hora del pago"
                          prepend-icon="mdi-clock-time-four-outline"
                          disabled
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="menuTime && !readonlyDateTime"
                        v-model="paymentTime"
                        full-width
                        @click:minute="$refs.menu.save(paymentTime)"
                      ></v-time-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-select
                      v-model="paymentEdit.currency"
                      :prepend-icon="mdiFlag"
                      :items="currencies"
                      :readonly="readonlyCurrency"
                      label="Divisa"
                      v-on:change="changeCurrency"
                      persistent-hint
                      disabled
                    ></v-select>
                  </v-col>
                  <v-col v-if="paymentEdit.currency != 'usd'" cols="12" md="3">
                    <v-text-field
                      v-model="exchangeRate"
                      :prepend-icon="mdiSwapHorizontal"
                      label="Tipo de cambio"
                      autocomplete="off"
                      readonly
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row v-if="gateway.value != 'courtesy'">
                  <v-col v-if="gateway.value != 'cash'" cols="12" md="4">
                    <v-text-field
                      v-model="paymentEdit.transactionId"
                      autocomplete="off"
                      :prepend-icon="mdiPoundBoxOutline"
                      :rules="[rules.required]"
                      label="ID transaccion"
                    ></v-text-field>
                  </v-col>
                  <v-col v-if="gateway.value == 'transfer-deposit'" cols="12" md="4">
                    <v-select
                      v-model="transferType"
                      :items="trasfersTypes"
                      :prepend-icon="mdiBankTransfer"
                      label="Transferencia a"
                      item-text="label"
                      item-value="value"
                      persistent-hint
                      return-object
                      :rules="[rules.required]"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      label="Cantidad"
                      v-model="paymentEdit.amount"
                      autocomplete="off"
                      :prepend-icon="mdiCurrencyUsd "
                      disabled
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row v-if="payment.gatewayPayment != 'cash' && payment.gatewayPayment != 'courtesy'">
                  <v-col cols="12" md="8">
                    <v-file-input
                      v-model="image"
                      label="Comprobante"
                      :rules="[rules.required]"
                      accept="image/png, image/jpeg, image/bmp"
                      prepend-icon="mdi-paperclip"
                    ></v-file-input>
                  </v-col>
                  <v-col v-if="gateway.value != 'cash'" cols="12" md="4">
                    <a v-if="payment.image" :href="payment.image" target="_blank">
                      <img :src="payment.image" alt="" width="150px">
                      Ver 
                    </a>
                  </v-col>
                </v-row>
                <div v-if="gateway.value == 'courtesy'">
                  <v-text-field
                    v-model="payment.authorizedBy"
                    autocomplete="off"
                    :prepend-icon="mdiAccountCheck"
                    label="Quien autorizo"
                    :rules="[rules.required]"
                  ></v-text-field>
                </div>
                <v-textarea
                  v-model="paymentEdit.description"
                  name="input-7-1"
                  label="Descripcion"
                  rows="3"
                  :prepend-icon="mdiMessageAlertOutline "
                  placeholder="Ingrese una descripción"
                ></v-textarea>
              <!-- </v-col> -->
              <!-- <v-col cols="12" md="7">
                <v-simple-table>
                  <template v-slot:default>
                    <tbody>
                      <tr>
                        <td>Total Reserva</td>
                        <td>{{ numberFormat(reservation.total) }} {{reservation.currency}}</td>
                      </tr>
                      <tr>
                        <td>Balance Actual</td>
                        <td>{{ numberFormat(reservation.balance) }} {{reservation.currency}}</td>
                      </tr>
                      <tr>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>Cantidad pagada por el cliente</td>
                        <td>
                          <v-text-field
                            v-model="customerAmountGived"
                            autocomplete="off"
                            :prepend-icon="mdiCurrencyUsd "
                            :rules="[rules.required, rules.number]"
                          ></v-text-field>
                        </td>
                      </tr>
                      <tr>
                        <td>Cantidad a cobrar</td>
                        <td>
                          <v-text-field
                            v-model="payment.amount"
                            autocomplete="off"
                            :prepend-icon="mdiCurrencyUsd "
                            :rules="[rules.required, rules.number]"
                          ></v-text-field>
                        </td>
                      </tr>
                      <tr>
                        <td>{{ newBalance > 0 ? 'Balance' : 'Cambio' }}</td>
                        <td>
                          {{ newBalanceMXN }} mxn || 
                          {{ newBalanceUSD }} usd
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col> -->
            <!-- </v-row> -->
          </v-form>
        </v-card-text>

        <v-divider></v-divider>
        <v-progress-linear v-if="uploadValue !== null" :value="uploadValue"></v-progress-linear>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="closeDialog"
          >
            Cerrar
          </v-btn>
          <v-btn
            color="primary"
            :disabled="!validForm"
            @click="validateForm"
          >
            <v-icon>{{mdiContentSave}}</v-icon> Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      :timeout="-1"
    >
      {{snackbar.message}}
      <template v-slot:action="{ attrs }">
        <v-btn
          color=""
          text
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          <v-icon>{{mdiClose}}</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
  import { mdiContentSave, mdiCurrencyUsd, mdiFlag, mdiSwapHorizontal, mdiMessageAlertOutline, mdiPoundBoxOutline, mdiBankTransfer, mdiClose, mdiAccountCheck } from '@mdi/js';
  import ReservationModel from '@/models/Reservation';
  import CurrencyModel from '@/models/Currency';
  import Loading from 'vue-loading-overlay';
  import {NumberFormat} from "@/helpers/NumberFormat";

  export default {
    name:"ReservationEditPayment",
    props:['reservation', 'open', 'payment'],
    components:{Loading},
    data() {
      let now = this.$moment();
      return {
        customerAmountGived: '',
        mdiContentSave:mdiContentSave,
        mdiCurrencyUsd :mdiCurrencyUsd ,
        mdiSwapHorizontal:mdiSwapHorizontal,
        mdiMessageAlertOutline:mdiMessageAlertOutline,
        mdiPoundBoxOutline:mdiPoundBoxOutline,
        mdiBankTransfer:mdiBankTransfer,
        mdiAccountCheck:mdiAccountCheck,
        mdiFlag: mdiFlag,
        mdiClose: mdiClose,
        openDialog:this.open,
        gateways:[
          {name:'Efectivo', value:'cash'},
          {name:'Terminal Bancaria(TPV)', value:'tpv-bank'},
          {name:'Clip Terminal', value:'clip-terminal'},
          {name:'Transferencia/Depósito', value:'transfer-deposit'},
          {name:'Paypal Solicitud', value:'paypal-rq'},
          {name:'Evopay Solicitud', value:'evopay-rq'},
          {name:'Clip Solicitud', value:'clip-rq'},
          // {name:'Cheques', value:'bank-cheque'},
          {name:'Cortesía', value:'courtesy'},
        ],
        trasfersTypes: [
          { label: 'Cuenta', value: 'fiscal' },
          { label: 'Tarjeta', value: 'personal' },
        ],
        snackbar:{
          show:false,
          color:'',
          message:''
        },
        image:null,
        menuDate:false,
        menuTime:false,
        validForm:true,
        uploadValue:null,
        now:now,
        //currencies:['mxn', 'usd'],
        currencies:[],
        currenciesExchange:{},
        paymentEdit: {...this.payment},
        paymentDate: this.payment.getFormatedDate('YYYY-MM-DD'),//now.format('YYYY-MM-DD'),
        paymentTime: this.payment.getFormatedDate('HH:mm'), //now.format('HH:mm'),
        gateway:{name:this.payment.getGatewayName(), value:this.payment.gatewayPayment},
        transferType:null,
        exchangeRate:'',
        rules:{
          required: v => !!v || 'El campo es obligatorio',
          number : v => {
            const pattern = /^[0-9]+([.][0-9]+)?$/
            return pattern.test(v) || 'Cantidad invalida'
          },
        },
        myLoading:{
          isLoading:false,
          fullPage:false
        }
      }
    },
    methods:{
      closeDialog() {
        this.openDialog = false;
        this.$emit('close-dialog-edit-payment', false)
      },
      numberFormat(number) {
        if (number) {
          return NumberFormat.format(number, 2, ',', '.')
        }
        return 0
      },
      numberRound(number) {
        if (number) {
          return NumberFormat.round(number, 2)
        }
        return 0
      },
      async validateForm() {
        if (this.$refs.form.validate()) {
          try {
            // this.payment.exchangeRate = 1 / this.exchangeRate;
            // this.payment.date = this.$moment(this.paymentDate + ' ' + this.paymentTime).toDate();
            let model = new ReservationModel;
            this.myLoading.isLoading = true
            if (this.payment.gatewayPayment != 'cash' && this.payment.gatewayPayment != 'courtesy') {
              let upload = model.uploadImagePayment(this.reservation.id, this.image)
              await upload.ref.on(`state_changed`, snapshot=> {
                this.uploadValue = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
              },
              error => {
                console.log(error.message);
                this.showSnackbarError('Error al subir la imagen del pago')
              },
              async () => {
                this.uploadValue = 100;
                await upload.ref.snapshot.ref.getDownloadURL().then(async (url) => {
                  let payment = {... this.paymentEdit}
                  if (this.payment.gatewayPayment == 'transfer-deposit') {
                    payment.transferType = this.transferType.value;
                  }
                  payment.image = url
                  this.myLoading.isLoading = false
                  await model.updatePayment(this.reservation.id, payment, this.payment)
                  this.image = null
                  this.uploadValue = null
                  this.resetData();
                  this.showSnackbarSuccess('El pago se ha actualizado correctamente')
                  this.myLoading.isLoading = false
                  this.closeDialog();
                });
              });
            } else {
              let payment = {...this.paymentEdit}
              await model.updatePayment(this.reservation.id, payment, this.payment)
              this.resetData();
              this.myLoading.isLoading = false
              this.showSnackbarSuccess('El pago se ha actualizado correctamente')
              this.closeDialog();
            }
          } catch (e) {
            console.log('error', e);
            this.showSnackbarError('Error al actualizar el pago');
          }
        }
      },
      showSnackbarSuccess(message) {
        this.snackbar.show = true;
        this.snackbar.message = message;
        this.snackbar.color = 'green';
      },
      showSnackbarError(message) {
        this.snackbar.show = true;
        this.snackbar.message = message;
        this.snackbar.color = 'red';
      },
      changeCurrency() {
        this.$nextTick(() => {
          const exchangeRate = this.currenciesExchange[this.payment.currency].exchangeRate
          this.exchangeRate = 1 / exchangeRate
        })
      },
      changeGateway() {
        this.$nextTick(() => {
          this.payment.transactionId = '';
          this.payment.gatewayPayment = this.gateway.value;
          this.payment.currency = 'mxn'
          this.exchangeRate = 1 / this.currenciesExchange['mxn'].exchangeRate;
        })
      },
      resetData() {
        this.paymentDate = this.now.format('YYYY-MM-DD')
        this.paymentTime = this.now.format('HH:mm')
        this.gateway = {name:'Efectivo', value:'cash'}
        this.transferType = null
        this.validForm = true
        this.customerAmountGived = ''
        this.exchangeRate = 1 / this.currenciesExchange['mxn'].exchangeRate
        /*this.payment = {
          transactionId:'',
          gatewayPayment:'cash',
          description: '',
          exchangeRate: '',
          fee:'0',
          date:null,
          currency:'mxn',
          image:null,
          amount:'',
        }*/
      }
    },
    async mounted() {
      const currencyModel = new CurrencyModel
      const snap = await currencyModel.getAll().get()
      snap.forEach((c) => {
        this.currencies.push(c.id)
        this.currenciesExchange[c.id] = c.data()
      })
      this.exchangeRate = 1 / this.currenciesExchange['mxn'].exchangeRate;
      //console.log('reservation', this.reservation);
    },
    computed:{
      readonlyDateTime() {
        return true
        // return this.gateway.value == 'cash';
      },
      readonlyCurrency() {
        switch (this.gateway.value) {
          case 'paypal-rq':
          case 'cash':
            return false;
          default :
          return true;
        }
      },
      amountConverted() {
        if (this.reservation.currency !== this.payment.currency) {
          if (this.reservation.currency === 'mxn' && this.payment.currency === 'usd') {
            return this.numberRound(this.payment.amount / this.reservation.exchangeRate)
          } else {
            return this.numberRound(this.payment.amount / this.exchangeRate)
          }
        } else {
          return this.payment.amount
        }
      },
      newBalance() {
        return this.reservation.balance - this.amountConverted
      },
      newBalanceMXN() {
        if (this.reservation.currency == 'usd' && this.currenciesExchange['mxn']) {
          return this.newBalance * (1 / this.currenciesExchange['mxn'].exchangeRate)
        }
        return this.newBalance
      },
      newBalanceUSD() {
        if (this.reservation.currency == 'mxn' && this.currenciesExchange['mxn']) {
          return this.newBalance / (1 / this.currenciesExchange['mxn'].exchangeRate)
        }
        return this.newBalance
      },
    },
    watch:{
      open(newValue) {
        this.openDialog = newValue;
      }
    }
  }
</script>
<style>
  /* sm */
  @media (min-width: 600px ) {
    .v-dialog{
      max-width: 100%;
    }
  }
  /* md */
  @media (min-width: 960px ) {
    .v-dialog{
      max-width: 900px;
    }
  }
  /* lg */
  @media (min-width: 1264px ) {
    .v-dialog{
      max-width: 960px;
    }
  }
  /* xl */
  @media (min-width: 1904px ) {
    .v-dialog{
      max-width: 960px;
    }
  }
</style>