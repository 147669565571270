<template>
  <v-card class="mx-auto elevation-1 card-history">
    <v-card-title class="info white--text">
      Historial
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text style="max-height: 80vh;overflow-y: auto;">
      <Loading :active="loadingHistory" :fullPage="false" />
      <v-timeline dense v-if="items">
        <v-slide-x-reverse-transition
          group
          hide-on-leave
        >
          <v-timeline-item
            v-for="(item, index) in items"
            :key="item.id"
            small
            :color="item.style"
            :icon="item.iconAlternative"
            fill-dot
          >
             <v-card
              :color="item.style"
              dark
            >
              <v-card-title class="">
                {{item.title}}
              </v-card-title>
              <v-card-text class="white text--primary">
                <p class="mb-0">{{ item.description }}</p>
                <a
                  v-if="checkHaveFile(item)"
                  :href="item.files[0]" style="text-decoration:none;"
                  target="_blank"
                >
                  <v-icon small color="blue-grey darken-2">mdi-file</v-icon>
                   Abrir
                </a>
                <v-btn
                  v-if="showBtnChanges(item, index)"
                  @click="toogle(index)"
                  x-small
                  outlined
                  color="light-blue darken-4"
                >
                  Ver cambios
                </v-btn>
                <v-btn
                  v-if="hideBtnChanges(item, index)"
                  @click="toogle(index)"
                  x-small
                  outlined
                  color="light-blue darken-4"
                >
                  Ocultar cambios
                </v-btn>
                <div v-if="showChanges[index].open" class="table-responsive mt-2">
                  <table v-if="item.type === 'responsives'" class="table table-bordered">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Nombre</th>
                        <th>Checkin</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(r, iR) in item.fields.responsives.new" :key="iR">
                        <td>{{iR}}</td>
                        <td>
                          {{item.fields.responsives.old[iR] ? item.fields.responsives.old[iR].fullName : '--'}} <br>
                          {{r.fullName}}
                        </td>
                        <td><img width="100px" v-if="r.checkin" :src="r.sign"/></td>
                      </tr>
                    </tbody>
                  </table>
                  <table v-else-if="item.type === 'paymentUpdate'" class="table table-bordered">
                    <thead>
                      <tr>
                        <th>Campo 2</th>
                        <th>Antes</th>
                        <th>Ahora</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(field, i) in item.fields" :key="i">
                        <td>{{i}}</td>
                        <td>
                          <span v-if="i == 'date'">{{$moment.unix(field.old.seconds).format('YYYY-MM-DD HH:mm')}}</span>
                          <a v-else-if="i == 'image' && field.old" :href="field.old">Ver</a>
                          <span v-else>{{ field.old }}</span>
                        </td>
                        <td>
                          <span v-if="i == 'date'">{{$moment.unix(field.new.seconds).format('YYYY-MM-DD HH:mm')}}</span>
                          <a v-else-if="i == 'image' && field.new" :href="field.new">Ver</a>
                          <span v-else>{{ field.new }}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table v-else class="table table-bordered">
                    <thead>
                      <tr>
                        <th>Campo</th>
                        <th>Antes</th>
                        <th>Ahora</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(field, i) in item.fields" :key="i">
                        <td>{{i}}</td>
                          <template v-if="i == 'nameGuides'" >
                            <td>
                              <ul>
                                <li  v-for="(name, index) in reservation.nameGuides" :key="index">
                                  {{ field.old.seconds ? $moment.unix(field.old.seconds).format('YYYY-MM-DD') : field.old[index] }}
                                </li>
                              </ul>
                            </td>
                            <td>
                              <ul>
                                <li  v-for="(name, index) in reservation.nameGuides" :key="index">
                                  {{ field.new.seconds ? $moment.unix(field.new.seconds).format('YYYY-MM-DD') : field.new[index] }}
                                </li>
                              </ul>
                            </td>
                          </template>
                          <template v-else>
                            <td>{{ field.old.seconds ? $moment.unix(field.old.seconds).format('YYYY-MM-DD') : field.old }}</td>
                            <td>{{ field.new.seconds ? $moment.unix(field.new.seconds).format('YYYY-MM-DD') : field.new }}</td>
                          </template>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="d-flex">
                  <small>{{ item.getFormatedDate() }}</small>
                  <v-icon color="blue-grey darken-2" class="ml-2" small>mdi-account</v-icon>
                  <small> {{ item.user }}</small>
                </div>
              </v-card-text>
            </v-card>
          </v-timeline-item>
        </v-slide-x-reverse-transition>
      </v-timeline>
    </v-card-text>
  </v-card>
</template>
<script>
  import Reservation from "@/models/Reservation"
  import "@/assets/css/tables.css"
  export default {
    name:"History",
    props:['reservation'],
    data() {
      return {
        showChanges:[],
        loadingHistory: true,
        items:null
      }
    },
    mounted() {
      const reservationModel = new Reservation
      reservationModel.getHistory(this.reservation.id).onSnapshot((docs) => {
        this.items = []
        this.showChanges = []
        docs.forEach((doc) => {
          this.items.push(doc.data())
          this.showChanges.push({open:false})
        })
        this.loadingHistory = false
      })
    },
    methods: {
      checkHaveFile(item) {
        if ((item.type && item.type == 'payment' && item.icon == 'mdiCashUsdOutline') || item.icon == 'mdiPaperclip') {
          return item.files && item.files.length &&item.files[0]
        } else {
          return false 
        }
      },
      toogle(index) {
        this.showChanges[index].open = !this.showChanges[index].open
      },
      showBtnChanges(item, index) {
        return (item.icon === `mdiPencil` || item.type == 'paymentUpdate') && !this.showChanges[index].open
      },
      hideBtnChanges(item, index) {
        return (item.icon === `mdiPencil` || item.type == 'paymentUpdate') && this.showChanges[index].open
      }
    }
  }
</script>
<style>
  .v-application--is-ltr .card-history .v-timeline--dense:not(.v-timeline--reverse):before {
    left: calc(25px - 1px) !important;
    right: initial;
  }
  .card-history .v-timeline-item__divider {
    min-width: 50px;
  }
  .card-history .v-timeline--dense .v-timeline-item__body {
    max-width: calc(100% - 50px);
  }
</style>