class Currency {
  id;
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.code = data.code;
    this.exchangeRate = data.exchangeRate;
    this.status = data.status;
  }
}
var DataConverter = {
  toFirestore: function(item) {
    return {
      name : item.name,
      code : item.code,
      exchangeRate : item.exchangeRate,
      status : item.status,
    }
  },
  fromFirestore: function(snapshot, options){
    const data = snapshot.data(options);
    data.id = snapshot.id
    return new Currency(data)
  }
}
export {Currency, DataConverter} 