<template>
  <div>
    <v-simple-table dense >
      <template v-slot:default>
        <tbody>
          <tr>
            <td>ID</td>
            <td>{{ payment.transactionId }}</td>
          </tr>
          <tr>
            <td>Fecha</td>
            <td>{{ $moment.unix(payment.date.seconds).format('DD MMMM YYYY - HH:mm') }}hrs</td>
          </tr>
          <tr>
            <td>Cantidad pagada</td>
            <td>{{ payment.getPaidAmount() }} {{ payment.getCurrency() }}</td>
          </tr>
          <tr>
            <td>Comisión</td>
            <td>{{ payment.getFee() }} {{ payment.getCurrency() }}</td>
          </tr>
          <tr>
            <td>Tipo de cambio</td>
            <td>{{ payment.getExchangeRateConverted() }}</td>
          </tr>
          <tr>
            <td>Comprobante</td>
            <td>
              <a v-if="payment.image" :href="payment.image" target="_blank">Abrir</a>
              <span v-else>Pendiente</span>
            </td>
          </tr>
          <tr>
            <td>Descripción</td>
            <td>
              {{ payment.description }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>
<script>
  export default {
    name:'TpvBankDetails',
    props:['payment']
  }
</script>