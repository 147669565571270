<style>
  .cursor-pointer .v-list-item{
    cursor: pointer;
  }
</style>
<template>
  <div>
    <v-expansion-panels
      v-model="panel"
      multiple
    >
      <v-expansion-panel>
        <v-expansion-panel-header>
          <h3 class="v-card__title d-flex" style="padding: 0">
          <v-icon class="green--text" v-if="rComputed.confirmationInfo" >{{mdiCheckCircle}}</v-icon> Datos de la reservacion
          <span class="ml-auto primary--text">${{total}}<small>{{rComputed.currency}}</small></span>
          </h3>
        </v-expansion-panel-header>
        <v-divider></v-divider>
        <v-expansion-panel-content class="">
          <v-form
            ref="form"
            v-model="formDataReservation"
            lazy-validation
          >
            <Loading :active="loadingFormDataReservation" :fullPage="true" />
            <v-row>
              <v-col sm="12" md="12" class="d-flex">
                <small>Creada: {{rComputed.createdDate}} desde <b class="primary--text">{{reservation.panelFormated()}}</b></small>
              </v-col>
              <v-col sm="12" md="12" class="d-flex my-4">
                <v-divider/>
              </v-col>
              <v-col sm="12" md="12" class="d-flex flex-wrap">
                <div class="">
                  <small class="d-block">Status</small>
                  <BtnStatus
                    :icon="rComputed.status == 'checkin' || rComputed.status == 'noshow' ? '' : mdiPencil"
                    :status="rComputed.status"
                    :disabled="rComputed.status == 'checkin' || rComputed.status == 'noshow'"
                    @click.native="dialogStatus = true"
                  />
                </div>
                <div class="ml-2">
                  <small class="d-block">Pago</small>
                  <BtnPaymentStatus 
                    :icon="mdiPencil"
                    :status="rComputed.paymentStatus"
                    :balance="reservation.balance"
                    />
                </div>
                <div class="ml-2">
                  <small class="d-block">Responsivas</small>
                  <v-btn
                    x-small
                    :color="reservation.responsives ? 'success' : ''"
                    @click="goToResponsives"
                  >
                    {{reservation.responsives ? 'Modificar' : 'Firmar' }}
                  </v-btn>
                </div>
                <div class="ml-2">
                  <small class="d-block">ID/INE</small>
                  <v-btn
                    x-small
                    :color="classBtnIDs"
                    @click="openDialogUploadIDs"
                  >
                    Ingresar
                  </v-btn>
                </div>
                <div class="ml-2">
                  <small class="d-block">Acciones</small>
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        color="primary"
                        dark
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>{{mdiMenuDown }}</v-icon>
                        Seleccione
                      </v-btn>
                    </template>
                    <v-list class="cursor-pointer">
                      <v-list-item >
                        <v-list-item-title @click="validateBeforeSendCoupon">Enviar cupón</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
                <div class="ml-2">
                  <small class="d-block">Pago</small>
                  <v-btn
                    x-small
                    color="primary"
                    dark
                    @click="addPayment"
                    >
                      <v-icon>mdi-plus</v-icon> Agregar
                  </v-btn>
                </div>
                <div v-if="reservation.status == 'confirmed' && reservation.paymentStatus == 'confirmed'" class="ml-2">
                  <small class="d-block">{{'\xa0'}}</small>
                  <v-btn
                    x-small
                    color="info"
                    dark
                    @click="$router.push({path:`/backend/taquilla/reservations/${reservation.id}/checkin`})"
                    >
                      <v-icon>mdi-account-multiple-check</v-icon> Checkin
                  </v-btn>
                </div>
                <div v-if="reservation.status == 'confirmed'" class="ml-2">
                  <small class="d-block">{{'\xa0'}}</small>
                  <v-btn
                    x-small
                    color="error"
                    dark
                    @click="dialogStatusNoshow = true"
                    >
                      <v-icon>mdi-account-question-outline</v-icon> Noshow
                  </v-btn>
                </div>
              </v-col>
              <v-col sm="12" md="12" class="d-flex my-4">
                <v-divider/>
              </v-col>
              <v-col sm="12" md="8" class="my-2">
                <v-text-field
                  v-model="rComputed.tour"
                  label="Tour"
                  required
                  readonly
                  dense
                ></v-text-field>
              </v-col>
              <v-col sm="12" md="4" class="my-2">
                <v-text-field
                  v-model="rComputed.code"
                  label="Codigo"
                  required
                  readonly
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="6" md="6">
                <v-menu
                  v-model="menuDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="rDate"
                      label="Fecha"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    
                    v-model="rDate"
                    @input="menuDate = false"
                    
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row v-if="rComputed.panel === 'divers'">
              <v-col sm="6" md="3" lg="2">
                <v-text-field
                  v-model="rComputed.guides"
                  label="Guías"
                  prepend-icon="mdi-diving-helmet"
                  :rules="[rules.required]"
                  type="number"
                  min="0"
                  dense
                ></v-text-field>
              </v-col>
              <v-col sm="6" md="3" lg="2">
                <v-text-field
                  v-model="rComputed.adults"
                  label="Clientes"
                  prepend-icon="mdi-diving-scuba"
                  :rules="[rules.required]"
                  type="number"
                  min="0"
                  dense
                ></v-text-field>
              </v-col>
              <v-col sm="6" md="3" lg="2">
                <v-text-field
                  v-model="rComputed.immersions"
                  label="Inmersiones extras"
                  prepend-icon="mdi-diving-scuba-tank-multiple"
                  :rules="[rules.required]"
                  type="number"
                  min="0"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="rComputed.panel !== 'divers'">
              <v-col sm="6" md="6" lg="3">
                <v-text-field
                  v-model="rComputed.adults"
                  label="Adultos"
                  :prepend-icon="mdiHumanMale"
                  :rules="[rules.required]"
                  type="number"
                  min="0"
                  dense
                ></v-text-field>
              </v-col>
              <v-col sm="6" md="6" lg="3">
                <v-text-field
                  v-model="rComputed.elderlies"
                  label="A. Mayores"
                  :prepend-icon="mdiHumanCane"
                  :rules="[rules.required]"
                  type="number"
                  min="0"
                  dense
                ></v-text-field>
              </v-col>
              <v-col sm="6" md="6" lg="3">
                <v-text-field
                  v-model="rComputed.children"
                  label="Niños"
                  :prepend-icon="mdiHumanMaleChild"
                  :rules="[rules.required]"
                  type="number"
                  min="0"
                  dense
                ></v-text-field>
              </v-col>
              <v-col sm="6" md="6" lg="3">
                <v-text-field
                  v-model="rComputed.infants"
                  label="Infantes"
                  :prepend-icon="mdiBabyCarriage"
                  :rules="[rules.required]"
                  type="number"
                  min="0"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="rComputed.transportation">
              <v-col sm="6" md="4">
                <v-text-field
                  :value="rComputed.transportation.type"
                  :prepend-icon="mdiTaxi"
                  label="Transporte"
                  required
                  readonly
                  dense
                ></v-text-field>
              </v-col>
              <v-col sm="6" md="4">
                <v-text-field
                  :value="rComputed.hotel"
                  :prepend-icon="mdiTaxi"
                  label="Hotel"
                  required
                  dense
                ></v-text-field>
              </v-col>
              <v-col sm="6" md="4">
                <v-text-field
                  :value="rComputed.room"
                  label="Habitación"
                  required
                  dense
                ></v-text-field>
              </v-col>
              <v-col sm="6" md="6" v-if="rComputed.pickupDateTime">
                <v-text-field
                  :value="$moment(rComputed.pickupDateTime.seconds * 1000).format('HH:mm a')"
                  :prepend-icon="mdiTaxi"
                  label="Pickup"
                  required
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-textarea
              v-if="rComputed.confirmationInfo"
              v-model="rComputed.confirmationDescription"
              name="input-7-1"
              label="Texto de confirmación"
              rows="2"
              :prepend-icon="mdiMessageAlertOutline"
            ></v-textarea>
            <v-btn
              small
              color="primary"
              dark
              @click="validateFormDataReservation"
            >
              <v-icon>mdi-content-save</v-icon>
              Guardar
            </v-btn>
          </v-form>
          <v-dialog
            v-model="dialogConfirmSendCoupon"
            persistent
            max-width="290"
          >
            <v-card>
              <v-card-title class="headline red white--text">
                Enviar cupón
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text class="pt-5">
                <Loading :active="loadingSendCoupon" :fullPage="true" />
                Esta seguro que desea enviar el cupón?
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  @click="dialogConfirmSendCoupon = false"
                >
                  Cancelar
                </v-btn>
                <v-btn
                  color="primary"
                  @click="sendCoupon"
                >
                  <v-icon>{{ mdiSend }}</v-icon>
                  Enviar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <h3 class="v-card__title" style="padding: 0">
            Cliente
            <v-btn
              v-if="rComputed.panel === 'divers'"
              elevation="2"
              class="ml-3"
              x-small
              color="info"
            >{{rComputed.user.business}}</v-btn>
            <!-- <small class="ml-3 primary--text" v-if="rComputed.panel === 'divers'">{{rComputed.user.business}}</small> -->
          </h3>
        </v-expansion-panel-header>
        <v-divider></v-divider>
        <v-expansion-panel-content class="pt-3">
          <Customer :reservation="reservation"/>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-if="reservation.landing">
        <v-expansion-panel-header>
          <h3 class="v-card__title" style="padding: 0">
            Landing
          </h3>
        </v-expansion-panel-header>
        <v-divider></v-divider>
        <v-expansion-panel-content class="pt-3">
          <div class="d-flex">
            <div>
              <v-img
                class="white--text align-end"
                width="300px"
                :src="rComputed.landingImg"
              >
                <v-card-title>{{rComputed.landingTitle}}</v-card-title>
              </v-img>
            </div>
            <div>

              <v-card-subtitle class="pt-1 pb-0">
                Landing : <strong>{{rComputed.landingTitle}}</strong>
              </v-card-subtitle>
              
              <v-card-subtitle class="pt-1 pb-0">
                Fecha de reserva : <strong>{{rComputed.createdDate}} </strong>
              </v-card-subtitle>

              <v-card-text class="text--primary my-4">
                <a target="_blank" :href="getApiUrl()+rComputed.landingUrl">Visitar landing</a>
              </v-card-text>

              <!-- <v-card-actions>
                <a target="_blank" :href="item.landingUrl">Visitar landing</a>
              </v-card-actions> -->
            </div>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header><h3 class="v-card__title" style="padding: 0">Extras</h3></v-expansion-panel-header>
        <v-divider></v-divider>
        <v-expansion-panel-content class="pt-3">
          <Extras :reservation="reservation"/>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header><h3 class="v-card__title" style="padding: 0">Tarifas y precios</h3></v-expansion-panel-header>
        <v-divider></v-divider>
        <v-expansion-panel-content class="pt-3">
          <Rates :reservation="reservation"/>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <h3 class="v-card__title d-flex" style="padding: 0">
            Pagos
            <span :class="(reservation.balance ? `warning--text` : 'success--text') + ` ml-auto`">Balance: ${{reservation.balance}}<small>{{reservation.currency}}</small></span>
          </h3>
        </v-expansion-panel-header>
        <v-divider></v-divider>
        <v-expansion-panel-content class="pt-3">
          <Payments ref="payments" :reservation="reservation"/>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      :timeout="-1"
    >
      {{snackbar.message}}
      <template v-slot:action="{ attrs }">
        <v-btn
          color=""
          text
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          <v-icon>{{mdiClose}}</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <UpdateStatus
      :reservation="reservation"
      :open.sync="dialogStatus"
      @close-dialog-update-status="dialogStatus=false"
      @on-success-update-status="statusSuccess($event)"
      @on-error-update-status="statusError($event)"
    />
    <UpdateStatusToCheckin
      :reservation="reservation"
      :open.sync="dialogStatusCheckin"
      @close-dialog-update-status="dialogStatusCheckin=false"
      @on-success-update-status="statusCheckinSuccess($event)"
      @on-error-update-status="statusCheckinError($event)"
    />
    <UpdateStatusToNoshow
      :reservation="reservation"
      :open.sync="dialogStatusNoshow"
      @close-dialog-update-status="dialogStatusNoshow=false"
      @on-success-update-status="statusNoshowSuccess($event)"
      @on-error-update-status="statusNoshowError($event)"
    />
    <UpdatePaymentStatus
      :reservation="reservation"
      :open.sync="dialogPaymentStatus"
      @close-dialog-update-payment-status="dialogPaymentStatus=false"
      @on-success-update-payment-status="statusSuccess($event)"
      @on-error-update-payment-status="statusError($event)"
    />
    <UploadIDs
      :reservation="reservation"
      :open.sync="dialogUploadIDs"
      @close-dialog-upload-ids="dialogUploadIDs = false"
      />
  </div>
</template>
<script>
  import Vue from 'vue'
  import { mdiCalendar, mdiPound, mdiHumanMale, mdiHumanMaleChild, mdiBabyCarriage, mdiMenuDown, mdiTaxi, mdiCheckCircle,mdiMessageAlertOutline, mdiSend, mdiClose, mdiPencil } from "@mdi/js";
  import { mdiHumanCane  } from '@/assets/icons/mdi-icons-extended.js';
  import BtnStatus from "@/components/reservations/BtnStatus";
  import {NumberFormat} from "@/helpers/NumberFormat";
  import BtnPaymentStatus from "@/components/reservations/BtnPaymentStatus";
  import Customer from "@/components/reservations/Customer";
  import Rates from "@/components/reservations/Rates";
  import ReservationModel from '@/models/Reservation';
  import Payments from "@/components/reservations/payments/Payments";
  import Extras from "@/components/reservations/extras/Extras";
  import UpdateStatus from "@/components/reservations/UpdateStatus";
  import UpdatePaymentStatus from "@/components/reservations/UpdatePaymentStatus";
  import UpdateStatusToCheckin from "@/components/reservations/UpdateStatusToCheckin";
  import UpdateStatusToNoshow from "@/components/reservations/UpdateStatusToNoshow";
  import UploadIDs from "@/components/reservations/UploadIDs";

  import firebase from 'firebase/app';
  import 'firebase/auth';
  
  const axios = require('axios');
  export default{
    name:"EditReservation",
    props:['reservation'],
    components:{BtnStatus,BtnPaymentStatus,Customer,Rates,Payments,UpdateStatus, UpdatePaymentStatus, UploadIDs, Extras, UpdateStatusToCheckin, UpdateStatusToNoshow},
    data() {
      const totalPax = this.reservation.adults + this.reservation.elderlies + this.reservation.children + this.reservation.infants
      return {
        mdiPencil:mdiPencil,
        mdiSend:mdiSend,
        mdiClose:mdiClose,
        mdiMessageAlertOutline:mdiMessageAlertOutline,
        mdiCheckCircle:mdiCheckCircle,
        mdiTaxi:mdiTaxi,
        mdiMenuDown :mdiMenuDown ,
        mdiCalendar:mdiCalendar,
        mdiPound:mdiPound,
        mdiHumanCane:mdiHumanCane,
        mdiHumanMale:mdiHumanMale,
        mdiHumanMaleChild:mdiHumanMaleChild,
        mdiBabyCarriage:mdiBabyCarriage,
        panel: [0,1],
        items: 5,
        //NumberFormat: NumberFormat,
        totalPax: totalPax,
        classBtnIDs: '',
        dialogStatus: false,
        dialogStatusCheckin: false,
        dialogStatusNoshow: false,
        dialogPaymentStatus: false,
        dialogUploadIDs: false,
        formDataReservation: true,
        loadingSendCoupon: false,
        loadingFormDataReservation: false,
        dialogConfirmSendCoupon: false,
        menuDate: false,
        rDate: this.$moment.unix(this.reservation.date.seconds).format('YYYY-MM-DD'),
        snackbar:{
          show:false,
          color:'',
          message:''
        },
        rules:{
          required: v => {
            if (v === 0) {
              return true 
            } else {
              return !!v || 'El campo es obligatorio'
            }
          },
          number : v => {
            const pattern = /^[0-9]+([.][0-9]+)?$/
            return pattern.test(v) || 'Cantidad invalida'
          },
        },
      }
    },
    computed:{
      rComputed() {
        let r = {...this.reservation};
        r.date = this.$moment.unix(r.date.seconds).format('DD-MMMM-YYYY');
        r.createdDate = this.$moment.unix(r.createdDate.seconds).format('DD-MMMM-YYYY h:mm:ss a');
        return r;
      },
      total() {
        return NumberFormat.format(this.reservation.total, 2, ',', '.');
      }
    },
    methods: {
      getApiUrl() {
        return process.env.VUE_APP_API_URL
      },
      validateBeforeSendCoupon() {
        if (this.reservation.status == 'pending') {
          this.showSnackbarError('No es posible enviar un cupon si la reservación esta pendiente')
        } else {
          if (this.reservation.transportation && !this.reservation.pickupDateTime) {
            this.showSnackbarError('No es posible enviar un cupon si no se ha asignado la hr de pickup')
          } else if (this.reservation.transportation && this.reservation.paymentStatus !== 'confirmed') {
            this.showSnackbarError('No es posible enviar un cupon si la reservación tiene transporte y el pago no esta confirmado')
          } else if (this.reservation.status === 'confirmed' && this.reservation.paymentStatus === 'confirmed') {
            this.dialogConfirmSendCoupon = true
          } else if (this.reservation.status === 'confirmed' && !this.reservation.transportation) {
            this.dialogConfirmSendCoupon = true
          } else {
            this.showSnackbarError(`No es posible enviar un cupon si la reservación esta ${this.reservation.status} y el pago esta ${this.reservation.paymentStatus}`)
          }
        }
      },
      showSnackbarSuccess(message) {
        this.snackbar.show = true;
        this.snackbar.message = message;
        this.snackbar.color = 'green';
      },
      showSnackbarError(message) {
        this.snackbar.show = true;
        this.snackbar.message = message;
        this.snackbar.color = 'red';
      },
      statusSuccess() {
        this.showSnackbarSuccess(`El status se actulizo correctamente`)
      },
      statusError() {
        this.showSnackbarError(`Hubo un error al actualizar el status de la reservación`)
      },
      statusCheckinSuccess() {
        this.showSnackbarSuccess(`El status se actulizo correctamente`)
      },
      statusCheckinError() {
        this.showSnackbarError(`Hubo un error al actualizar el status de la reservación`)
      },
      statusNoshowSuccess() {
        this.showSnackbarSuccess(`El status se actulizo correctamente`)
      },
      statusNoshowError() {
        this.showSnackbarError(`Hubo un error al actualizar el status de la reservación`)
      },
      paymentStatusSuccess() {
        this.showSnackbarSuccess(`El status de pago se actulizo correctamente`)
      },
      paymentStatusError() {
        this.showSnackbarError(`Hubo un error al actualizar el status de pago de la reservación`)
      },
      sendCoupon() {
        this.loadingSendCoupon = true
        firebase.auth().currentUser.getIdToken(true).then((idToken) => {
          var config = {
            method: 'post',
            url: process.env.VUE_APP_API_URL+'/api/reservation/send-coupon',
            data:{ id: this.reservation.id },
            headers:{
              'token':idToken
            }
          };
          axios(config)
          .then(async (response) => {
            if (response.data.status == 'OK') {
              let model = new ReservationModel
              await model.addHistorySendCoupon(this.reservation.id, {title:'Cupon enviado', description: ''})
              this.dialogConfirmSendCoupon = false 
              this.loadingSendCoupon = false
              this.showSnackbarSuccess('El cupón se ha enviado correctamente')
            } else {
              this.dialogConfirmSendCoupon = false 
              this.loadingSendCoupon = false
              this.showSnackbarError('El cupón no se ha enviado, intente de nuevo. Si el problema persiste contacte al Administrador')
            }
          })
          .catch((error) => {
            console.log(error);
            this.loadingSendCoupon = false
            this.showSnackbarError('El cupón no se ha enviado, intente de nuevo. Si el problema persiste contacte al Administrador')
          });
        }).catch((error) => {
          // Handle error
          console.log('error al obtener el token', error);
          this.loadingSendCoupon = false
          this.showSnackbarError('El cupón no se ha enviado, intente de nuevo. Si el problema persiste contacte al Administrador')
        });
      },
      openDialogUploadIDs() {
        this.dialogUploadIDs = true
      },
      async validateFormDataReservation() {
        if (this.$refs.form.validate()) {
          this.loadingFormDataReservation = true
          let data = {
            date: firebase.firestore.Timestamp.fromDate(Vue.moment(this.rDate).toDate()),
            adults: this.rComputed.adults,
            elderlies: this.rComputed.elderlies,
            children: this.rComputed.children,
            infants: this.rComputed.infants,
            //hotel: this.rComputed.hotel,
            //room: this.rComputed.room,
            //pickupDateTime: this.rComputed.pickupDateTime,
            //confirmationDescription
          }
          if (this.rComputed.transportation) {
            data.hotel = this.rComputed.hotel
            data.room = this.rComputed.room
            data.pickupDateTime = this.rComputed.pickupDateTime
          }
          if (this.rComputed.confirmationInfo) {
            data.confirmationDescription = this.rComputed.confirmationDescription
          }
          let model = new ReservationModel
          const r = {...this.reservation}
          await model.updateWithHistory(this.reservation.id, data, r)
          this.loadingFormDataReservation = false
          this.showSnackbarSuccess('La información se ha guardado correctamente')
        }
      },
      goToResponsives() {
        this.$router.push(
          {path:`/backend/taquilla/reservations/${this.reservation.id}/responsives`}
        )
      },
      addPayment() {
        this.panel = [4]
        //wait to open expansion panel payments 
        setTimeout(() => {
          this.$refs.payments.openDialogAddPayment()
        }, 200);
      }
    },
    mounted() {
      const reservationModel = new ReservationModel
      reservationModel.getIdentifications(this.reservation.id).onSnapshot((querySnapshot) => {
        if (this.totalPax == querySnapshot.size) {
          this.classBtnIDs = 'success'
        }
      });
      //.get()
    }
  }
</script>